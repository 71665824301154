import ElevationScroll from '@/components/elevationScroll';
import LayoutAnimation from '@/components/framerAnimations/layoutAnimation';
import AnimatedLayoutWrapperFadeInOut from '@/components/page/animate';
import type { ContainerProps, Theme } from '@mui/material';
import { AppBarProps, Box, Container, useMediaQuery } from '@mui/material';
import { useAtomValue } from 'jotai/index';
import type { ReactNode } from 'react';
import useGetDeviceInfo from '../../hooks/useGetDeviceInfo';
import { companyModeIdAtom } from '../_app.component';
import PublicFooter from './footer/publicFooter';
import MobileContent from './navbar/mobileContent';
import WebContent from './navbar/webContent';

export default function StaticPageLayout( {
	navbarContent,
	children,
	navbarContainerProps,
	footerContainerProps,
	elevationScrollProps,
	hasAnimation = false,
}: {
	children: ReactNode,
	navbarContent?: ReactNode,
	navbarContainerProps?: ContainerProps,
	footerContainerProps?: ContainerProps,
	elevationScrollProps?: AppBarProps,
	hasAnimation?: boolean
} ) {
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'md' ) );
	const { isCloverDevice } = useGetDeviceInfo();
	const companyId = useAtomValue( companyModeIdAtom );
	
	const navbar = (
		<ElevationScroll
			showBorder={Boolean( navbarContainerProps?.maxWidth )}
			{...elevationScrollProps}
			sx={{
				pt          : 'env(safe-area-inset-top)',
				maxWidth    : ( { breakpoints } ) => breakpoints.values[ navbarContainerProps?.maxWidth as any ],
				mx          : 'auto',
				px          : { sm: 3 },
				borderTop   : 0,
				borderBottom: 1,
				...elevationScrollProps?.sx,
			}}>
			{navbarContent || ( isMobile && !isCloverDevice
				? <MobileContent/>
				: (
					<Container
						sx={{ px: { xs: 2, sm: '0 !important' } }}
						maxWidth={navbarContainerProps?.maxWidth || 'xl'}>
						<WebContent/>
					</Container>
				) )}
		</ElevationScroll>
	);
	
	const footer = companyId ? null : footerContainerProps?.maxWidth ? (
		<Container
			sx={{
				bgcolor    : '#000000',
				border     : 1,
				borderColor: 'divider',
			}}
			maxWidth={footerContainerProps?.maxWidth}>
			<PublicFooter/>
		</Container>
	) : <PublicFooter/>;
	
	return (
		<Box
			display='flex'
			flexDirection='column'
			minHeight='100vh'
			sx={{
				'.borderedContainer': {
					borderRight: 1,
					borderLeft : 1,
					borderColor: 'divider',
					overflow   : 'hidden',
				},
			}}>
			{navbar}
			<Box flexGrow={1}>
				<AnimatedLayoutWrapperFadeInOut>
					{hasAnimation ? (
						<LayoutAnimation variant='fadeInUp'>
							{children}
						</LayoutAnimation>
					) : children}
				</AnimatedLayoutWrapperFadeInOut>
			</Box>
			{footer}
		</Box>
	);
}
