import { Box, ListItem, styled } from '@mui/material';
import { Fragment, SetStateAction } from 'react';
import { mobileMenuLinks } from '../routes';
import NavbarLink from './navbarLink';

const DropDown = styled( 'div' )( {} );

export default function MenuDropDownContent( {
	showNavbar,
	setShowNavbar,
}: {
	showNavbar?: boolean,
	setShowNavbar?: ( value: SetStateAction<boolean> ) => void
} ) {
	return (
		<Fragment>
			<DropDown
				sx={{
					bgcolor   : 'background.paper',
					transition: 'all .5s',
					boxShadow : 'rgb(0 0 0 / 15%) 0 10px 12px -2px',
					transform : showNavbar ? 'translateY(0)' : 'translateY(-20px)',
					visibility: showNavbar ? 'visible' : 'hidden',
					display   : 'block',
					position  : 'absolute',
					top       : 0,
					left      : 0,
					right     : 0,
					pt        : 6,
					zIndex    : 'tooltip',
					opacity   : showNavbar ? 1 : 0,
				}}>
				{mobileMenuLinks.map( ( link, index ) => (
					<ListItem
						key={index}
						sx={{
							borderTop      : '1px solid rgba(0,0,0,0.05)',
							opacity        : showNavbar ? 1 : 0,
							visibility     : showNavbar ? 'visible' : 'hidden',
							transform      : showNavbar ? 'translateY(0)' : 'translateY(-20px)',
							transition     : 'all .5s',
							py             : 2,
							transitionDelay: `${index * .05}s`,
						}}>
						<NavbarLink
							sx={{
								textDecoration: 'none !important',
								color         : 'text.primary',
								fontSize      : 16,
								fontWeight    : 400,
							}}
							href={link.href}>
							{link.name}
						</NavbarLink>
					</ListItem>
				) )}
			</DropDown>
			<Box
				sx={{
					transition: 'all .5s',
					visibility: showNavbar ? 'visible' : 'hidden',
					opacity   : showNavbar ? 1 : 0,
					bgcolor   : 'rgba(0, 0, 0, .5)',
					height    : '100vh',
					position  : 'absolute',
					left      : 0,
					top       : 0,
					right     : 0,
				}}
				onClick={() => setShowNavbar( false )}
			/>
		</Fragment>
	);
}
